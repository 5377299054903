var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              footerShow: true,
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属公司",
                        rules: [
                          {
                            required: true,
                            message: "所属公司不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "companyName",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.companyName))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        rules: [
                          {
                            required: true,
                            message: "所属项目不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "communityName",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.communityName))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "社群名称",
                        rules: [
                          {
                            required: true,
                            message: "社群名称不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "groupName",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.groupName))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "职位",
                        rules: [
                          {
                            required: true,
                            message: "请选择职位",
                            trigger: "change",
                          },
                        ],
                        prop: "userRole",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.userRoleOpss, width: _vm.width },
                        model: {
                          value: _vm.form.userRole,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "userRole", $$v)
                          },
                          expression: "form.userRole",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.community_id !== undefined
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "用户姓名",
                            prop: "userId",
                            rules: [
                              {
                                required: true,
                                message: "请选择用户姓名",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select4",
                            _vm._b(
                              {
                                attrs: {
                                  communityId: _vm.community_id,
                                  width: _vm.width,
                                },
                                on: { onChange: _vm.changeUserName },
                                model: {
                                  value: _vm.form.userId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "userId", $$v)
                                  },
                                  expression: "form.userId",
                                },
                              },
                              "v-select4",
                              _vm.houseParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }