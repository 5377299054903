<template>
  <div class="container">
    <!-- 新增 -->
    <form-panel
      v-bind="submitConfig"
      ref="formPanel"
      :form="form"
      :footerShow="true"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block>
          <el-form-item
            label="所属公司"
            :rules="[
              { required: true, message: '所属公司不能为空', trigger: 'blur' },
            ]"
            prop="companyName"
          >
            <span>{{ form.companyName }}</span>
          </el-form-item>

          <el-form-item
            label="所属项目"
            :rules="[
              { required: true, message: '所属项目不能为空', trigger: 'blur' },
            ]"
            prop="communityName"
          >
            <span>{{ form.communityName }}</span>
          </el-form-item>

          <el-form-item
            label="社群名称"
            :rules="[
              { required: true, message: '社群名称不能为空', trigger: 'blur' },
            ]"
            prop="groupName"
          >
            <span>{{ form.groupName }}</span>
          </el-form-item>

          <el-form-item
            label="职位"
            :rules="[
              { required: true, message: '请选择职位', trigger: 'change' },
            ]"
            prop="userRole"
          >
            <v-select
              v-model="form.userRole"
              :options="userRoleOpss"
              :width="width"
            />
          </el-form-item>

          <el-form-item
            label="用户姓名"
            prop="userId"
            :rules="[
              {
                required: true,
                message: '请选择用户姓名',
                trigger: 'change',
              },
            ]"
            v-if="community_id !== undefined"
          >
            <v-select4
              v-model="form.userId"
              v-bind="houseParams"
              :communityId='community_id'
              :width="width"
              @onChange="changeUserName"
            ></v-select4>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { Col2Detail, Col2Block } from "components/bussiness";
import { getNeighborClubInfo, getOwnerList, socialMember } from "./api";
import { vSelect4 } from 'components/control'

export default {
  name: "MemberAddNew",
  components: {
    Col2Detail,
    Col2Block,
    "v-select4": vSelect4
  },
  data() {
    return {
      width: 182,

      form: {
        companyName: "",
        communityName: "",
        groupName: "",
        groupId: "", // 社群id
        userId: undefined, // 用户id
        userRole: undefined, // 职位
        houseId: undefined, 
      },
      userRoleOpss: [
        {
            text: '社长',
            value: 3
        },
        {
            text: '管理员',
            value: 1
        },
        {
            text: '普通成员',
            value: 0
        }
      ],
      submitConfig: {
        submitUrl: socialMember,
        submitContentType: "application/json",
        submitMethod: "PUT",
      },
      community_id: undefined,
      houseParams: {
        searchUrl: getOwnerList,
        request: {
          text: 'ownerName',
          value: 'id'
        },
        response: {
          text: 'name',
          value: 'id'
        },
        method: 'get'
      }
    };
  },
  created() {
    const { neighborClubId, companyName } = this.$route.query;
    this.form.groupId = neighborClubId;
    this.getNeighborClubInfoFun(neighborClubId);
    this.form.companyName = companyName;
  },
  methods: {
    submitBefore(data) {
      console.log(data, "---data");
      delete data.communityName
      delete data.companyName
      delete data.groupName
      return true;
    },
    // 获取公司名称
    getNeighborClubInfoFun(id) {
      let _this = this;
      this.$axios.get(getNeighborClubInfo + `?id=${id}`).then((res) => {
        if (res.status == "100") {
          console.log('res.data++++', res.data)
          const { communityName, neighborClubName, community_id } = res.data;
          _this.form.communityName = communityName;
          _this.form.groupName = neighborClubName;
          _this.community_id = community_id
        }
      });
    },
    changeUserName(data) {
        this.form.houseId = data.houseId
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
